/**, *:before, *:after {
  -webkit-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  -webkit-text-size-adjust: none;
}*/
.pageContent.expanded{
    margin-left: 83px !important;
}
.pageContent{
    transition: all 0.4s ease-in-out;
    margin-bottom:60px;
    margin-left: 260px !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}