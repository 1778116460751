body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --custom-scrollbar-thumb-color: #c0c0c0;
  --custom-scrollbar-track-color: white;
  --dark-component-hover-color: rgba(255, 255, 255, 0.08);
  --dark-component-active-color: rgba(175,175,175,0.25);
}